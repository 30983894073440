const bar = [
  {
    id: 11,
    title: '【転職コレクション】ユーザ登録をしましょう',
    text: 'お客様\n\nお世話になっております、\n転職コレクション事務局です。\n\nこの度は転職コレクションにご登録くださいまして、\n誠にありがとうございます。\n\n以下の確認番号をご入力下さい。\n------------\n確認番号：【   VERIFY_NUMBER   】\n------------\n※この番号を使ってメールアドレスの有効性を\n確認しています。\n\n引き続きよろしくお願いいたします。\n\n転職コレクション',
  },
  {
    id: 2,
    title: '【転職コレクション】アカウント作成が完了しました',
    text: 'お客様\n\nお世話になっております、転職コレクション事務局です。\n\n下記の通り、アカウント作成が完了しました。\n\n▽ご登録内容\nメールアドレス：MAIL_ADDRESS\nパスワード：(セキュリティのため非表示)\n\n引き続きよろしくお願いいたします。\n\n転職コレクション',
  },
  {
    id: 3,
    title: '【転職コレクション】お申込みが完了しました',
    text: 'お客様\n\nお世話になっております、転職コレクション事務局です。\n\n下記の通り、求人情報へのお申込みが完了しました。\n\n▽お申込み内容\n-------------\n・メールアドレス：MAIL_ADDRESS\n・職種名：JOB_TYPE\n・勤務地：JOB_PLACE\n・職種カテゴリー：JOB_CATEGORY\n・雇用形態：JOB_STYLE\n-------------\n\n引き続きよろしくお願いいたします。\n\n転職コレクション',
  },
]

export default bar
